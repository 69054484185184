import { IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ReactElement, useState } from "react";
import { LightbulbSVG } from "../../../assets/LightbulbSVG";
import { HybridTooltip } from "../../../components/ui/HybridTooltip";
import { isMobileDevice } from "../../../lib/uiHelpers";

interface TooltipProps {
  text: React.ReactNode | string;
  children: ReactElement<any, any>;
}

export function Tooltip({ text, children }: TooltipProps) {
  const classes = styles();
  const isMobile = isMobileDevice();
  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = () => setOpen(true);

  return (
    <HybridTooltip
      open={open}
      onOpen={handleTooltipOpen}
      onTooltipDismiss={handleTooltipClose}
      title={
        <div className={classes.tooltip}>
          <div className={classes.lightbulb}>
            <LightbulbSVG />
          </div>
          <div className={classes.text}>{text}</div>
          {isMobile && (
            <IconButton
              size="small"
              className={classes.iconButton}
              onClick={e => {
                e.stopPropagation(); // Stop event from propagating
                handleTooltipClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
      }
    >
      {children}
    </HybridTooltip>
  );
}

const styles = makeStyles(_theme => ({
  tooltip: {
    display: "flex",
    justifyContent: "space-between",
  },
  text: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "20px",
    marginLeft: 8,
    marginRight: 8,
    marginTop: 12,
  },
  iconButton: {
    height: "fit-content",
    color: "#FFFFFF",
  },
  lightbulb: {
    marginTop: 12,
  },
}));
