import { useEffect, useMemo, useState } from "react";
import { Theme, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { PhotoSVG } from "../SVGs/PhotoSVG";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { ImageDropzone } from "../../../components/ui/ImageDropzone";
import { APIRes, GuidedSetupScreen } from "../../../types";
import { sharedStyles } from "../shared.styles";
import { APIAuthClient, convertFileToB64 } from "../../../lib";
import {
  getVolunteerCampaignId,
  setToast,
  updateVolunteerSetupData,
} from "../../../state";
import { Tooltip } from "../components/Tooltip";
import { RootState } from "../../../types/state";

export function Photo(props: GuidedSetupScreen) {
  const {
    activeScreen,
    setDisableNext,
    handleNext,
    nextTriggeredBy,
    setNextTriggeredBy,
    setSubmitting,
  } = props;
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();
  const currImage = useSelector(
    (state: RootState) => state.volunteerSetup.picture,
  );
  const volunteerCampaignId = useSelector(getVolunteerCampaignId);
  const [file, setFile] = useState<File | undefined>();
  const [removeCurrImage, setRemoveCurrImage] = useState(false);
  const classes = styles({
    emptyState: !file && (!currImage || removeCurrImage),
  });

  useEffect(() => {
    if (activeScreen === "PICTURE") {
      if (!currImage && !file) setDisableNext(true);
      else setDisableNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeScreen, currImage, file]);

  useEffect(() => {
    if (nextTriggeredBy === "PICTURE") {
      submit();
      setNextTriggeredBy(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTriggeredBy]);

  const tooltipText = (
    <div>
      Opt for a photo in landscape (horizontal) orientation{" "}
      <div className={classes.tooltipRDText}>Recommended dimensions:</div>540 x
      350 pixels / 5.5 x 3.5 inches
    </div>
  );

  const onImageRemoved = () => {
    if (currImage) setRemoveCurrImage(true);
  };

  const submit = useMemo(
    () => async () => {
      // if they are keeping the current picture OR removing it - move on
      if (!file && !removeCurrImage) return handleNext();
      setSubmitting(true);
      const update: any = {};
      if (file) update.pictureBase64 = await convertFileToB64(file);
      else update.removePicture = true;

      const res = await APIAuthClient.put<any, APIRes>(
        `/volunteer_campaigns/${volunteerCampaignId}`,
        update,
      );
      const { error, errorMessage, data } = res;
      if (error) {
        dispatch(setToast(errorMessage));
        setSubmitting(false);
        return;
      }
      const _picture = data.picture;
      dispatch(updateVolunteerSetupData({ picture: _picture }));
      handleNext();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [file, volunteerCampaignId, removeCurrImage],
  );

  if (activeScreen !== "PICTURE") return <></>;
  return (
    <div className={sharedClasses.screenContainer}>
      <PhotoSVG />
      <h1 className={sharedClasses.screenHeader}>
        Add a photo of yourself to boost sales
      </h1>
      <p className={sharedClasses.screenParagraph}>
        Upload an engaging photo that will be displayed on your personal
        fundraising page and in your email communications to supporters.
      </p>

      <Tooltip text={tooltipText}>
        <div className={classes.recommendWrapper}>
          <div className={classes.recommend}>Image recommendations</div>
          <InfoIcon color="primary" />
        </div>
      </Tooltip>

      <ImageDropzone
        setFile={setFile}
        currImage={currImage}
        onImageRemoved={onImageRemoved}
      />
    </div>
  );
}

type StyleProps = {
  emptyState: boolean;
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  recommend: {
    fontSize: 14,
    letterSpacing: 0.1,
    color: theme.palette.primary.main,
    textDecoration: "underline",
    marginRight: 8,
  },
  recommendWrapper: {
    marginTop: 9,
    marginBottom: ({ emptyState }) => (emptyState ? 48 : 24),
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
}));
