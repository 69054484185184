import { Button, makeStyles } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import { Skeleton } from "@material-ui/lab";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "../../../types/state";
import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { paths } from "../../../types";

export function Contacts() {
  const classes = styles();
  const history = useHistory();
  const { contactsFetched, contacts } = useSelector(
    ({ contacts }: RootState) => contacts,
  );
  const [emailCount, setEmailCount] = useState(0);
  const [phoneCount, setPhoneCount] = useState(0);
  const [figuresCalculated, setFiguresCalculated] = useState(false);

  useEffect(() => {
    if (contactsFetched && Array.isArray(contacts)) {
      let _emailCount = 0;
      let _phoneCount = 0;
      contacts.forEach(({ email, phone }) => {
        if (email) _emailCount++;
        if (phone) _phoneCount++;
      });
      setEmailCount(_emailCount);
      setPhoneCount(_phoneCount);
      setFiguresCalculated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactsFetched, contacts]);

  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <div className={classes.title}>My contacts</div>
        <Button
          color="primary"
          endIcon={<NavigateNextIcon />}
          className={classes.manageButton}
          onClick={() => history.push(paths.CONTACTS)}
        >
          Manage contacts
        </Button>
      </div>

      <div className={classes.cards}>
        {!figuresCalculated && (
          <Fragment>
            <Skeleton
              variant="rect"
              className={classNames(classes.skeleton, classes.leftCard)}
            />
            <Skeleton variant="rect" className={classes.skeleton} />
          </Fragment>
        )}
        {figuresCalculated && (
          <Fragment>
            <div className={classNames(classes.card, classes.leftCard)}>
              <div className={classes.cardContent}>
                <div className={classes.number}>{emailCount}</div>
                <div>
                  <div className={classes.txt}>
                    Contacts with email addresses
                  </div>
                  {emailCount > 0 && (
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      startIcon={<EmailOutlinedIcon />}
                      className={classes.actionButton}
                      onClick={() =>
                        history.push(
                          `${paths.CONTACTS}?show_email_selector=true`,
                        )
                      }
                    >
                      SEND EMAILS
                    </Button>
                  )}
                  {emailCount < 1 && (
                    <div className={classes.addMsg}>
                      <span
                        className={classes.txtLink}
                        onClick={() => history.push(paths.CONTACTS)}
                      >
                        Add contacts
                      </span>{" "}
                      with email addresses to send fundraising emails
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={classes.card}>
              <div className={classes.cardContent}>
                <div className={classes.number}>{phoneCount}</div>
                <div>
                  <div className={classes.txt}>Contacts with phone numbers</div>
                  {phoneCount > 0 && (
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      startIcon={<SmsOutlinedIcon />}
                      className={classes.actionButton}
                      onClick={() => history.push(paths.VOLUNTEER_EZ_SHARE)}
                    >
                      SEND TEXTS
                    </Button>
                  )}
                  {phoneCount < 1 && (
                    <div className={classes.addMsg}>
                      <span
                        className={classes.txtLink}
                        onClick={() => history.push(paths.CONTACTS)}
                      >
                        Add contacts
                      </span>{" "}
                      with phone numbers to send fundraising texts
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    marginBottom: 40,
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 16,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.14,
    lineHeight: "22px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      letterSpacing: 0.11,
    },
  },
  manageButton: {
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.37,
    height: 30,
    borderRadius: 15,
  },
  cards: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      overflowY: "auto",
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
  skeleton: {
    height: 99,
    width: "calc(50% - 12px)",
    borderRadius: 8,
    backgroundColor: "#F0F0F0",
    [theme.breakpoints.down("sm")]: {
      width: "calc(50% - 8px)",
      height: 94,
    },
    [theme.breakpoints.down("xs")]: {
      width: "unset",
      minWidth: 240,
    },
  },
  card: {
    width: "calc(50% - 12px)",
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    display: "flex",
    // alignItems: "center",
    padding: "24px 18px 20px 26px",
    [theme.breakpoints.down("sm")]: {
      width: "calc(50% - 8px)",
      padding: 16,
    },
    [theme.breakpoints.down("xs")]: {
      width: "unset",
      minWidth: 240,
    },
  },
  leftCard: {
    [theme.breakpoints.down("sm")]: {
      marginRight: 16,
    },
  },
  cardContent: {
    width: "100%",
    display: "flex",
  },
  number: {
    height: 50,
    minWidth: 52,
    borderRadius: 8,
    backgroundColor: "#FFF4F4",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.secondary2.main,
    fontSize: 32,
    fontWeight: 500,
    letterSpacing: 0,
    padding: "0 7px",
    marginRight: 16,
  },
  txt: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.11,
    lineHeight: "24px",
    paddingBottom: 6,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      letterSpacing: 0.1,
      lineHeight: "18px",
      fontWeight: 400,
    },
  },
  actionButton: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.3,
    color: theme.palette.secondary2.main,
    height: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
      letterSpacing: 0.37,
      height: 22,
    },
  },
  addMsg: {
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "18px",
    color: theme.palette.text.secondary2,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      letterSpacing: 0.11,
      lineHeight: "14px",
    },
  },
  txtLink: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
}));
