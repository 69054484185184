const DEFAULT_CO_PREFIX = 1;
const { REACT_APP_APP_ASSET_BASE_URL: APP_ASSET_BASE_URL } = process.env;

const customizedCompanyIds = {
  2: true,
  3: true,
  16: true,
  19: true,
  21: true,
  27: true,
  28: true,
  30: true,
};

export function getCompanyLogoHref(companyId: number) {
  const id = customizedCompanyIds[companyId] ? companyId : DEFAULT_CO_PREFIX;
  return `${APP_ASSET_BASE_URL}/${id}_admin-portal-logo.png`;
}

export function getCompanyFavicon32Href(companyId: number) {
  const id = customizedCompanyIds[companyId] ? companyId : DEFAULT_CO_PREFIX;
  return `${APP_ASSET_BASE_URL}/${id}_favicon-32x32.png`;
}

export function getCompanyFavicon16Href(companyId: number) {
  const id = customizedCompanyIds[companyId] ? companyId : DEFAULT_CO_PREFIX;
  return `${APP_ASSET_BASE_URL}/${id}_favicon-16x16.png`;
}

export function getCompanyAppleTouchHref(companyId: number) {
  const id = customizedCompanyIds[companyId] ? companyId : DEFAULT_CO_PREFIX;
  return `${APP_ASSET_BASE_URL}/${id}_apple-touch-icon.png`;
}

export function getIsImpersonating() {
  return Boolean(
    localStorage.getItem("orgImpersonationToken") ||
      localStorage.getItem("volunteerImpersonationToken") ||
      localStorage.getItem("repImpersonationToken") ||
      localStorage.getItem("companyImpersonationToken"),
  );
}

export const isMobileDevice = (): boolean => {
  let isMobile = false;
  try {
    isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  } catch (error) {
    console.error("Error checking device type:", error);
  }
  return isMobile;
};
