import { makeStyles, Tooltip } from "@material-ui/core";
import { ReactElement, useState, useEffect, ReactNode } from "react";
import { isMobileDevice } from "../../lib";

interface TooltipProps {
  title: NonNullable<ReactNode>;
  children: ReactElement<any, any>;
  open?: boolean;
  onOpen?: () => void;
  onTooltipDismiss?: () => void;
  placement?:
    | "bottom"
    | "left"
    | "right"
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start";
}

export function HybridTooltip({
  title,
  children,
  placement = "top",
  open: controlledOpen,
  onOpen,
  onTooltipDismiss,
}: TooltipProps) {
  const classes = styles();
  const [internalOpen, setInternalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Use controlled open state if provided, otherwise use internal state
  const open = controlledOpen !== undefined ? controlledOpen : internalOpen;

  // Detect device type on component mount
  useEffect(() => {
    const mobile = isMobileDevice();
    setIsMobile(mobile);
  }, []);

  const handleClose = () => {
    // Update internal state
    setInternalOpen(false);

    // Call parent callback if provided
    if (onTooltipDismiss) onTooltipDismiss();
  };

  const handleOpen = () => {
    // Update internal state
    setInternalOpen(true);

    // Call parent callback if provided
    if (onOpen) {
      onOpen();
    }
  };

  if (!children) {
    return null;
  }

  const handleClick = (_e: React.MouseEvent) => {
    if (open) handleClose();
    else handleOpen();
  };

  // Handle mouse enter for desktop devices
  const handleMouseEnter = () => {
    if (!isMobile) handleOpen();
  };

  // Handle mouse leave for desktop devices
  const handleMouseLeave = () => {
    if (!isMobile) handleClose();
  };

  return (
    <Tooltip
      open={open}
      onClose={handleClose}
      title={title}
      placement={placement}
      classes={{
        tooltip: classes.tooltipContainer,
        arrow: classes.arrow,
      }}
      // On mobile: disable hover/focus/touch listeners
      // On desktop: only disable focus listener
      disableFocusListener
      disableHoverListener={isMobile}
      disableTouchListener={isMobile}
      // Prevent clicks inside the tooltip from closing it
      interactive
    >
      <div
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={classes.childWrapper}
      >
        {children}
      </div>
    </Tooltip>
  );
}

const styles = makeStyles(_theme => ({
  tooltipContainer: {
    display: "flex",
    maxWidth: 340,
    backgroundColor: "#1b47aae6",
    color: "#FFFFFF",
    padding: "6px 8px 18px 12px",
    borderRadius: 8,
  },
  childWrapper: {
    display: "inline-block",
    cursor: "pointer",
  },
  arrow: {
    display: "none",
  },
}));
