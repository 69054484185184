import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import {
  Dialog,
  makeStyles,
  Tabs,
  Tab,
  Button,
  IconButton,
  Theme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { FieldArray } from "react-final-form-arrays";
import { Form, Field, useField } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { TextField } from "final-form-material-ui";
import { OnChange } from "react-final-form-listeners";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import {
  composeValidators,
  convertDonorToContactEmailOrPhoneValidation,
  emailValidation,
  requiredField,
} from "../../lib";
import { contactActions, getCampaignId } from "../../state";
import { ConvertSupportersCurrentContacts } from "./ConvertSupportersCurrentContacts";
import { useIsMobile } from "../../hooks/ui";
import { RootState, useAppDispatch } from "../../types/state";
import { ButtonSpinner } from "..";

interface Props {
  showDialog: boolean;
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
  mustComplete?: boolean;
}

export function ConvertSupportersToContacts({
  showDialog,
  setShowDialog,
  mustComplete = false,
}: Props) {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const classes = styles({ mustComplete });
  const campaign_id = useSelector(getCampaignId);
  const convertibleDonors = useSelector(
    (state: RootState) => state.contacts.convertibleDonors,
  );
  const [activeTab, setActiveTab] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [allAdded, setAllAdded] = useState(false);

  const clearAndClose = () => {
    dispatch(contactActions.clearConvertibleDonors());
    setShowDialog(false);
  };

  const addAll = async (convertibleDonors: any[]) => {
    setSubmitting(true);
    const contacts: any[] = [];
    convertibleDonors.forEach(cd => {
      const { added, hidden, email, phone, name } = cd;
      if (added || hidden) return;
      contacts.push({
        email: email ? email : null,
        phone: phone ? phone : null,
        name,
      });
    });
    const post = { contacts, campaign_id };
    const success = await dispatch(
      contactActions.addContacts(post, contacts.length, true),
    );
    if (success) {
      setAllAdded(true);
      setTimeout(() => {
        clearAndClose();
      }, 2000);
    } else {
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={showDialog}
      maxWidth={false}
      classes={{
        paper: classes.paper,
        paperWidthFalse: classes.paperWidthFalse,
        paperScrollPaper: classes.paperScrollPaper,
      }}
    >
      <div className={classes.topContent}>
        {!mustComplete && (
          <div className={classes.close}>
            <IconButton size="small" onClick={clearAndClose}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
        <div className={classes.headers}>
          <h1 className={classes.h1}>Add past supporters to your contacts</h1>
          <h2 className={classes.h2}>
            We found previous supporters who aren't in your fundraising contact
            list
          </h2>
        </div>
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          indicatorColor="primary"
          textColor="primary"
          variant={isMobile ? "fullWidth" : "standard"}
          classes={{ root: classes.tabs }}
        >
          <Tab label="Add contacts" />
          <Tab label="My fundraising contacts" wrapped />
        </Tabs>
      </div>

      <Form
        mutators={{ ...arrayMutators }}
        onSubmit={() => {}}
        initialValues={{ convertibleDonors }}
        render={({ values }) => {
          return (
            <form onSubmit={e => e.preventDefault()}>
              {activeTab === 0 && !allAdded && (
                <Fragment>
                  <div className={classes.addAllContainer}>
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      className={classes.addAll}
                      onClick={() => addAll(values.convertibleDonors)}
                      disabled={submitting}
                    >
                      ADD ALL
                      <ButtonSpinner show={submitting} />
                    </Button>
                  </div>

                  <div className={classes.bodyContainer}>
                    <div>
                      <FieldArray name="convertibleDonors">
                        {({ fields }) => {
                          return fields.map((fieldName, index) => {
                            return (
                              <Row
                                key={index}
                                fieldName={fieldName}
                                campaign_id={campaign_id}
                              />
                            );
                          });
                        }}
                      </FieldArray>
                    </div>
                  </div>
                </Fragment>
              )}
              <OnChange name="convertibleDonors">
                {rows => {
                  const notCompleted = rows.some(({ hidden }) => !hidden);
                  if (!notCompleted) clearAndClose();
                }}
              </OnChange>
            </form>
          );
        }}
      />

      {activeTab === 1 && !allAdded && <ConvertSupportersCurrentContacts />}

      {allAdded && (
        <div className={classes.allAdded}>
          <CheckCircleIcon className={classes.addedIcon} />
          <div className={classes.added}>Added</div>
        </div>
      )}
    </Dialog>
  );
}

interface RowProps {
  fieldName: string;
  campaign_id?: number;
  mustComplete?: boolean;
}
function Row({ fieldName, campaign_id, mustComplete }: RowProps) {
  const classes = styles({ mustComplete });
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const { email, phone, name } = useField(fieldName).input.value;
  const input = useField(fieldName).input;
  const hideInput = useField(`${fieldName}.hidden`).input;
  const addedInput = useField(`${fieldName}.added`).input;
  const [savePoint, setSavePoint] = useState<object>();
  const [hide, setHide] = useState(false);
  const [added, setAdded] = useState(false);
  const [edit, setEdit] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const addContact = async () => {
    setSubmitting(true);
    const post = {
      campaign_id,
      contacts: [
        { email: email ? email : null, phone: phone ? phone : null, name },
      ],
    };
    const success = await dispatch(contactActions.addContacts(post, 1, true));
    if (success) {
      setAdded(true);
      addedInput.onChange(true);
      setTimeout(() => {
        hideInput.onChange(true);
        setHide(true);
      }, 2000);
    } else {
      setSubmitting(false);
    }
  };

  const onEdit = () => {
    setEdit(true);
    setSavePoint({ email, phone, name });
  };

  const onCancel = () => {
    setEdit(false);
    input.onChange(savePoint);
  };

  if (hide || hideInput.value) return <></>;
  if (added) {
    return (
      <div className={classes.addedRow}>
        <CheckCircleIcon className={classes.addedIcon} />
        <div className={classes.added}>Added</div>
      </div>
    );
  }
  if (edit) {
    return (
      <div className={classes.editRow}>
        <div className={classes.inputs}>
          <Field
            name={`${fieldName}.name`}
            variant="outlined"
            component={TextField}
            size="small"
            validate={requiredField}
            className={classes.input}
          />
          <Field
            name={`${fieldName}.phone`}
            variant="outlined"
            component={TextField}
            size="small"
            validate={convertDonorToContactEmailOrPhoneValidation}
            className={classes.input}
          />
          <Field
            name={`${fieldName}.email`}
            variant="outlined"
            component={TextField}
            size="small"
            validate={composeValidators(
              emailValidation,
              convertDonorToContactEmailOrPhoneValidation,
            )}
            className={classes.input}
          />
        </div>
        <div className={classes.editActions}>
          <Button
            className={classes.add}
            color="primary"
            size="small"
            onClick={addContact}
            disabled={submitting}
          >
            Add
            <ButtonSpinner show={submitting} />
          </Button>
          <Button
            className={classes.cancel}
            color="primary"
            size="small"
            variant="text"
            onClick={onCancel}
          >
            CANCEL
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div className={classes.row}>
      {!isMobile && (
        <Fragment>
          <div>
            <IconButton size="small" className={classes.edit} onClick={onEdit}>
              <EditOutlinedIcon color="primary" />
            </IconButton>
          </div>
          <div className={classes.name}>{name}</div>
          <div className={classes.phone}>{phone}</div>
          <div className={classes.email}>{email}</div>
        </Fragment>
      )}

      {isMobile && (
        <div>
          <div className={classes.name}>{name}</div>
          {phone && <div className={classes.phone}>{phone}</div>}
          {email && <div className={classes.email}>{email}</div>}
          <Button
            color="primary"
            variant="text"
            size="small"
            startIcon={<EditOutlinedIcon />}
            className={classes.edit}
            onClick={onEdit}
          >
            EDIT
          </Button>
        </div>
      )}

      <div className={classes.addSkip}>
        <Button
          className={classes.add}
          color="primary"
          size="small"
          onClick={addContact}
          disabled={submitting}
        >
          Add
          <ButtonSpinner show={submitting} />
        </Button>
        {!mustComplete && (
          <Button
            className={classes.skip}
            color="primary"
            size="small"
            variant="text"
            onClick={() => {
              setHide(true);
              hideInput.onChange(true);
            }}
          >
            SKIP
          </Button>
        )}
      </div>
    </div>
  );
}

type StyleProps = {
  mustComplete?: boolean;
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  paper: {
    margin: 16,
  },
  paperWidthFalse: {
    maxWidth: "calc(100% - 32px)",
  },
  paperScrollPaper: {
    minHeight: "calc(100% - 64px)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "calc(100% - 32px)",
      maxHeight: "calc(100% - 32px)",
    },
  },
  topContent: {
    width: 800,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: 500,
    },
  },
  close: {
    position: "absolute",
    top: 6,
    right: 6,
  },
  headers: {
    padding: "16px 24px",
  },
  h1: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "32px",
    paddingBottom: 8,
    headers: {
      paddingRight: 8,
    },
  },
  h2: {
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: "24px",
  },
  tabs: {
    borderBottom: "1px solid #DBDEEE",
    [theme.breakpoints.up("md")]: {
      paddingLeft: 24,
    },
  },
  addAllContainer: {
    padding: "16px 0 8px 24px",
    borderBottom: "1px solid #C9CDDE",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 0 8px 16px",
      borderBottom: "1px solid #EAEBF3",
    },
  },
  addAll: {
    width: 66,
    borderRadius: 4,
    [theme.breakpoints.down("sm")]: {},
  },
  bodyContainer: {
    flex: "1 1 auto",
    overflowY: "auto",
    paddingBottom: 24,
  },
  row: {
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "46px .5fr .35fr .6fr 125px",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #C9CDDE",
    padding: "6px 6px 6px 20px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      borderBottom: "1px solid #EAEBF3",
      gridTemplateColumns: ({ mustComplete }) =>
        !mustComplete ? "1fr 121px" : "1fr 74px",
      padding: "8px 0 4px 16px",
      "& > *": {
        paddingRight: 16,
      },
    },
  },
  editRow: {
    borderBottom: "1px solid #C9CDDE",
    padding: "16px 24px",
    [theme.breakpoints.down("sm")]: {
      borderBottom: "1px solid #EAEBF3",
      padding: "0 16px 16px 16px",
    },
  },
  addedRow: {
    height: 52,
    borderBottom: "1px solid #C9CDDE",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      borderBottom: "1px solid #EAEBF3",
      height: 100,
    },
  },
  edit: {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      borderRadius: 4,
    },
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      letterSpacing: 0.1,
      paddingBottom: 6,
    },
  },
  phone: {
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: 12,
      letterSpacing: 0.4,
      lineHeight: "14px",
      color: theme.palette.text.secondary,
      paddingBottom: 6,
    },
  },
  email: {
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: 12,
      letterSpacing: 0.4,
      lineHeight: "14px",
      color: theme.palette.text.secondary,
      paddingBottom: 6,
    },
  },
  addSkip: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  add: {
    width: 57,
    minWidth: 57,
    borderRadius: 15,
  },
  skip: {
    width: 40,
    minWidth: "fit-content",
    marginLeft: 8,
  },
  cancel: {
    marginLeft: 8,
  },
  inputs: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  input: {
    width: 233,
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
      width: "100%",
    },
  },
  editActions: {
    marginTop: 15,
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      marginTop: 12,
    },
  },
  added: {
    fontSize: 14,
    letterSpacing: 0.15,
    marginLeft: 4,
  },
  addedIcon: {
    color: "#1AC846",
  },
  allAdded: {
    paddingTop: 40,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
